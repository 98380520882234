<template>
  <div id="app" class="mt-app">
    <b-container class="mt-app__header mt-app--container mb-3 mt-app--logo">
      <b-form-select v-if="!isLngDropdownHide" class="mt-app__language" v-model="$i18n.locale" :options="langsOptions" size="sm" @change="languageSelected"></b-form-select>
      <div class="mt-app__options" v-else-if='$store.state.token || $store.state.resubmissionToken'>
        <b-button class="mt-app__logout" @click="handleLogout" variant="danger">{{ $t('home.logout') }}</b-button>
        <div class="mt-app__timer" >
          {{ `${$t('home.logout')}:` }}
          <span :class="classTimer">{{ convertMsToDate(timerCount) }}</span>
        </div>
      </div>
    </b-container>
    <router-view class="mt-app__router mt-app--box mt-app--container pt-5 pb-5" :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import storage from '@/storage'
import { mapMutations } from 'vuex'
import { baseURL, path } from '@/axios'
const convertMsToDate = (duration) => {
  duration = Math.abs(duration)
  let seconds = Math.floor((duration / 1000) % 60)
  let minutes = Math.floor((duration / (1000 * 60)) % 60)
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds
  return minutes + ':' + seconds
}
const LOGOUT_TIMER = 60 * 60 * 1000 // min * sec * msec
const ALERT_TIMER = 5 * 60 * 1000 // min * sec * msec
export default {
  name: 'App',
  data () {
    return {
      langsOptions: [
        { value: 'en', text: 'English' },
        { value: 'mt', text: 'Malti' }
      ],
      timerInterval: null,
      timerCount: LOGOUT_TIMER,
      isLngDropdownHide: false
    }
  },
  computed: {
    classTimer () {
      return {
        'mt-app__timer--expire': this.isTimeExpires
      }
    },
    isTimeExpires () {
      return this.timerCount < ALERT_TIMER
    },
    isTimeExpired () {
      return this.timerCount <= 0
    }
  },
  methods: {
    ...mapMutations({
      resetUser: 'RESET_USER_INFO',
      resetResubmissionInfo: 'RESET_RESUBMISSION_INFO',
      setToken: 'SET_TOKEN',
      setResubmissionToken: 'SET_RESUBMISSION_TOKEN'
    }),
    languageSelected () {
      storage.set.lang(this.$i18n.locale)
    },
    handleLogout () {
      if (process.env.VUE_APP_ENV_PRODUCTION === 'true') {
        if (storage.get.resubmissionToken()) {
          this.$router.replace({ name: 'home' })
        } else {
          const token = this.$route.query.token || storage.get.token()
          window.location.href = `${baseURL}${path.ssologout}/${token}`
        }
      } else {
        this.handleExpiration()
      }
    },
    handleExpiration () {
      storage.remove.token()
      storage.remove.resubmissionToken()
      this.resetUser()
      this.resetResubmissionInfo()
      this.$router.replace({ name: 'home' })
    },
    convertMsToDate,
    timer () {
      const vm = this
      const start = +new Date() + LOGOUT_TIMER
      this.timerInterval = setInterval(() => {
        vm.timerCount = start - (+new Date())
        vm.isTimeExpired && vm.handleExpiration()
      }, 500)
    }
  },
  watch: {
    '$route' (to, from) {
      if (this.$route.name !== 'home') {
        this.isLngDropdownHide = true
      } else {
        this.isLngDropdownHide = false
      }
      if (to.name === 'home') {
        this.setToken(null)
        this.setResubmissionToken(null)
        this.$i18n.locale = storage.get.lang() || this.$i18n.locale
        clearInterval(this.timerInterval)
      } else if ((to.name === 'main-form' && from.name === 'home') ||
        (to.name === 'resubmission-form' && from.name === 'resubmission-redirect')) {
        this.$i18n.locale = storage.get.lang() || this.$i18n.locale
        clearInterval(this.timerInterval)
        this.timer()
      } else if (to.name === 'form-non-state' && from.name === 'home') {
        this.$i18n.locale = 'en'
        clearInterval(this.timerInterval)
        this.timer()
      }
    }
  },
  created () {
    this.$i18n.locale = storage.get.lang() || this.$i18n.locale
  }
}
</script>
<style lang="scss">
body{
  background-color: #eee !important;
  background-image: url('./assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.mt-app {
  @media screen and (max-width: 575px) {
    padding: 0 10px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &--container {
    padding: 10px 0 5px !important;
  }
  &--logo {
    background: url('~@/assets/mede2.png') no-repeat;
    background-size: 130px 150px;
    background-position-x: 100%;
    background-position-y: 10px;
    min-height: 150px;
    @media screen and (max-width: 575px) {
      background-size: 100px 120px;
      min-height: 120px;
    }
  }
  &__logout {
    align-self: start;
  }
  &__options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__timer {
    font-weight: bold;
    &--expire {
      color: #dc3545;
    }
  }
  &__language {
    width: 15% !important;
    min-width: 100px;
  }
  &__logo {
    width: auto;
  }
  &--box {
    border-radius: 7px;
    box-shadow:  0px 0px 3px 0px rgba(0,0,0,.3);
    background-color: #ffffff;
    min-height: 40vh;
  }
  &__buttons {
    margin-top: 6vh;
  }
  button:disabled {
    cursor: not-allowed;
  }
}
</style>
