import Vue from 'vue'
import Vuex from 'vuex'
import { service, path } from '@/axios'
import storage from '@/storage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userInfo: [],
    formId: '',
    isEligible: false,
    distanceToSchool: '',
    token: null,
    resubmissionInfo: [],
    supplementary: null,
    resubmissionToken: null,
    resubmissionTokenHasError: false,
    resubmissionAction: null,
    myMarker: {
      latitude: null,
      longitude: null
    },
    myMarker2: {
      latitude: null,
      longitude: null
    }
  },
  getters: {
    baseData: (state) => {
      return JSON.parse(state.resubmissionInfo.applicationFormData)
    },
    schoolLevelId: (state) => {
      return state.resubmissionInfo.schoolLevelId || ''
    },
    myMarker: (state) => {
      return state.myMarker
    },
    myMarker2: (state) => {
      return state.myMarker2
    }
  },
  mutations: {
    USER_INFO (state, payload) {
      state.userInfo = payload
    },
    RESUBMISSION_INFO (state, payload) {
      state.resubmissionInfo = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_RESUBMISSION_TOKEN (state, payload) {
      state.resubmissionToken = payload
    },
    RESET_USER_INFO (state) {
      state.userInfo = []
    },
    RESET_RESUBMISSION_INFO (state) {
      state.resubmissionInfo = []
    },
    SET_FORM_RESPONSE (state, payload) {
      const { referenceId, isEligible } = payload
      state.formId = referenceId
      state.isEligible = isEligible
    },
    SET_NON_STATE_FORM_RESPONSE (state, payload) {
      const { referenceId, isEligible, distanceToSchool } = payload
      state.formId = referenceId
      state.isEligible = isEligible
      state.distanceToSchool = distanceToSchool
    },
    SUPPLEMENTARY_RESPONSE (state, payload) {
      state.supplementary = payload
    },
    SET_ERROR_WITH_RESUBMISSION_TOKEN (state, payload) {
      state.resubmissionTokenHasError = payload
    },
    SET_RESUBMISSION_ACTION (state, payload) {
      state.resubmissionAction = payload
    },
    UPDATE_MY_MARKER_VALUES (state, payload) {
      state.myMarker = payload
    },
    UPDATE_MY_MARKER_2_VALUES (state, payload) {
      state.myMarker2 = payload
    }
  },
  actions: {
    getUserData ({ commit }, payload) {
      let endopoint = ''
      if (payload.schools === 'state') {
        endopoint = path.user
      } else if (payload.schools === 'non-state') {
        endopoint = path.nonStateUser
      } else {
        endopoint = 'error'
      }
      return new Promise((resolve, reject) => {
        service(payload.token)
          .get(endopoint)
          .then(response => {
            // const { user } = response.data.content
            // if (!user.userSiblings || user.userSiblings.length === 0) {
            //   return reject('noChildren')
            // }
            commit('USER_INFO', response.data.content)
            commit('SET_TOKEN', payload.token)
            storage.set.token(payload.token)
            resolve(response)
          })
          .catch((error) => {
            storage.remove.token()
            reject(error)
          })
      })
    },
    checkForExistingStudent ({ commit }, payload) {
      const { credentials } = payload
      const token = storage.get.token()
      return new Promise((resolve, reject) => {
        service(token)
          .post(`student`, credentials)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    submitForm ({ commit }, payload) {
      const token = storage.get.token()
      return new Promise((resolve, reject) => {
        service(token)
          .post(path.applicationForm, payload)
          .then(response => {
            commit('SET_FORM_RESPONSE', response.data.content)
            resolve(response.data.content)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submitNonStateForm ({ commit }, payload) {
      const token = storage.get.token()
      return new Promise((resolve, reject) => {
        service(token)
          .post(path.nonstateapplicationform, payload)
          .then(response => {
            commit('SET_NON_STATE_FORM_RESPONSE', response.data.content)
            resolve(response.data.content)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSupplementary ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        service(payload)
          .get(path.supplementary)
          .then(response => {
            commit('SUPPLEMENTARY_RESPONSE', response.data.content)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getResubmissionData ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        service(payload)
          .get(path.resubmission)
          .then(response => {
            commit('RESUBMISSION_INFO', response.data.content)
            commit('SET_RESUBMISSION_TOKEN', payload)
            storage.set.resubmissionToken(payload)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submitResubmissionForm ({ state, commit }, payload) {
      const token = storage.get.resubmissionToken()
      return new Promise((resolve, reject) => {
        service(token)
          .post(`${path.resubmission}/${state.resubmissionAction}`, payload)
          .then(response => {
            commit('SET_FORM_RESPONSE', response.data.content)
            resolve(response.data.content)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    rejectResubmissionForm ({ state, commit }) {
      const token = storage.get.resubmissionToken()
      return new Promise((resolve, reject) => {
        service(token)
          .post(`${path.resubmission}/${state.resubmissionAction}`)
          .then(response => {
            resolve(response.data.content)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})

export default store
