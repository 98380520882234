import Vue from 'vue'
import Router from 'vue-router'
import storage from '@/storage'
import store from '@/store'

Vue.use(Router)
const isLoginValid = (to, from, next) => {
  from.name === 'welcome' && storage.get.token() ? next({ name: 'welcome' }) : next()
}
const isNextRouteValid = (to, from, next) => {
  storage.get.token() && Object.keys(store.state.userInfo).length > 0 ? next() : next({ name: 'home' })
}
const hasTokenInPath = (to, from, next) => {
  to.query.hasOwnProperty('token') && to.query.token ? next() : next({ name: 'home' })
}
const isRedirectedForResubmission = (to, from, next) => {
  sessionStorage.getItem('resub_tkn_mt_application') && Object.keys(store.state.resubmissionInfo).length > 0 ? next() : next({ name: 'home' })
}
export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      beforeEnter: isLoginValid
    },
    {
      path: '/welcome',
      name: 'welcome',
      redirect: '/form',
      component: () => import('./views/Welcome.vue'),
      beforeEnter: isNextRouteValid
    },
    {
      path: '/form',
      name: 'main-form',
      component: () => import('./views/Form.vue'),
      beforeEnter: isNextRouteValid
    },
    {
      path: '/FormNonState',
      name: 'form-non-state',
      component: () => import('./views/FormNonState.vue'),
      beforeEnter: isNextRouteValid
    },
    {
      path: '/complaints',
      name: 'complaint-form',
      component: () => import('./views/ComplaintForm.vue'),
      beforeEnter: isNextRouteValid
    },
    {
      path: '/:lng/resubmission',
      name: 'resubmission-redirect',
      component: () => import('./views/ResubmissionRedirect.vue'),
      beforeEnter: hasTokenInPath
    },
    {
      path: '/resubmission-form',
      name: 'resubmission-form',
      component: () => import('./views/ResubmissionForm.vue'),
      beforeEnter: isRedirectedForResubmission
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
