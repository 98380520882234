export default {
  set: {
    lang: (language) => localStorage.setItem('lng_mt_application', language),
    token: (token) => sessionStorage.setItem('tkn_mt_application', JSON.stringify(token)),
    resubmissionToken: (token) => sessionStorage.setItem('resub_tkn_mt_application', JSON.stringify(token))
  },
  get: {
    lang: () => localStorage.getItem('lng_mt_application'),
    token: () => JSON.parse(sessionStorage.getItem('tkn_mt_application')),
    resubmissionToken: () => JSON.parse(sessionStorage.getItem('resub_tkn_mt_application'))

  },
  remove: {
    token: () => sessionStorage.removeItem('tkn_mt_application'),
    resubmissionToken: () => sessionStorage.removeItem('resub_tkn_mt_application')
  }
}
