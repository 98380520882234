import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from './translations/locale'
import store from './store'
import SimpleLineIcons from 'vue-simple-line'

import '@/sass/custom.scss'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

Vue.component('simple-line-icons', SimpleLineIcons)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.use(BootstrapVue)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBABNsHdJTVHbCl72x5hTgeiNW5ZYiQUA8',
    libraries: 'places'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
