import axios from 'axios'

let origin = ''
if ((process.env.NODE_ENV === 'development') || (process.env.VUE_APP_ENV_PRODUCTION === 'true')) {
  origin = process.env.VUE_APP_AXIOS_BASE_URL
} else {
  origin = `${window.location.origin}/`
}
// if ((process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV_PRODUCTION === 'false')) {
//   origin = `${window.location.origin}/`
// } else {
//   origin = process.env.VUE_APP_AXIOS_BASE_URL
// }
const baseURL = `${origin}SBN-services/webservices/malta/`
const timeout = 15000
const CancelToken = axios.CancelToken
const path = {
  citizensApp: 'citizensapp',
  user: 'user',
  nonStateUser: 'nonStateUser',
  applicationForm: 'applicationform',
  nonstateapplicationform: 'nonstateapplicationform',
  resubmission: 'resubmission',
  ssologout: 'ssologout',
  supplementary: 'supplementary'
}
const service = (token) => {
  const options = {
    baseURL,
    timeout
  }
  if (token) {
    options.headers = { 'Authorization': `Bearer ${token}` }
  } else if (!token && options.headers) {
    delete options.headers
  }
  return axios.create(options)
}
export {
  service,
  CancelToken,
  baseURL,
  path
}
